var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { appstraxAuth } from './auth';
import { ConfigManager } from './shared/config-manager';
export class AppstraxServices {
  static init(config) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!config.apiUrl) throw new Error('Appstrax Services: API URL missing');
      if (!config.apiKey) throw new Error('Appstrax Services: API Key missing');
      ConfigManager.setConfig(config.apiUrl, config.apiKey);
      // TODO: create an endpoint to do a status check
      yield appstraxAuth.init();
    });
  }
}
export * from './shared/models/query';