import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { AppstraxServices } from '@appstrax/services';
import Hotjar from '@hotjar/browser';

import { environment } from '../environments/environment';

// modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { NgrxRouterStoreModule } from './core/state/route/ngrx-router.module';

import { AuthInterceptor } from './core/http/auth-interceptor';
import { metaReducers, reducers } from './core/state';

// effects
import { CloudEffects } from './core/state/cloud';
import { ClusterEffects } from './core/state/cluster';
import { DomainsEffects } from './core/state/domain/domain.effects';
import { InvoiceEffects } from './core/state/invoice/invoice.effects';
import { PaymentMethodEffects } from './core/state/payment-method/payment-method.effects';
import { PhoneNumberEffects } from './core/state/phone-number';
import { RegionsEffects } from './core/state/region';
import { ReposEffects } from './core/state/repo/repo.effects';
import { SpacesEffects } from './core/state/space';
import { CapsuleEffects } from './core/state/capsule';
import { TeamsEffects } from './core/state/team';
import { UserEffects } from './core/state/user/user.effects';
import { AdminEffects } from './core/state/admin/admin.effects';
import { CreateCapsuleEffects } from './core/state/create-capsule/create-capsule.effects';
import { LandingPageEffects } from './core/state/landing-page';
import { NotificationsEffects } from './core/state/notification';
import { RecaptchaEffects } from './core/state/recaptcha/recaptcha.effects';
import { AgencyEffects } from './core/state/agency';
import { PlanEffects } from './core/state/plan';

// components
import { AppComponent } from './components/app.component';
import { SplashComponent } from './components/splash.component';

// services
import { StorageService } from './core/services/storage.service';
import { ThemeService } from './core/services/theme.service';
import { UiModule } from './core/ui/ui.module';
import { CapsuleBackupEffects } from './core/state/capsule/capsule-backup.effects';

AppstraxServices.init({
  apiUrl: environment.appstraxServicesApiUrl,
  apiKey: environment.appstraxServicesApiKey,
});

Hotjar.init(environment.hotjarSiteId, environment.hotjarVersion, {
  debug: !environment.production,
});

@NgModule({
  declarations: [AppComponent, SplashComponent],
  imports: [
    CoreModule,
    UiModule,
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot([
      DomainsEffects,
      InvoiceEffects,
      PaymentMethodEffects,
      ReposEffects,
      SpacesEffects,
      CapsuleEffects,
      CapsuleBackupEffects,
      TeamsEffects,
      UserEffects,
      CloudEffects,
      ClusterEffects,
      RegionsEffects,
      PhoneNumberEffects,
      PlanEffects,
      AdminEffects,
      CreateCapsuleEffects,
      LandingPageEffects,
      NotificationsEffects,
      RecaptchaEffects,
      AgencyEffects,
    ]),
    NgrxRouterStoreModule,
    MonacoEditorModule.forRoot(),
  ],
  providers: [
    ThemeService,
    StorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
