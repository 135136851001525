import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, of, timer } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  concatMap,
  takeUntil,
  mergeMap,
  take,
  startWith,
} from 'rxjs/operators';

import * as CapsuleActions from './capsule.actions';
import { Backup } from '../../models';
import { APIResponse } from '../../api';
import { environment } from 'src/environments/environment';

@Injectable()
export class CapsuleBackupEffects {
  constructor(private actions$: Actions<any>, private httpClient: HttpClient) { }

  //Start manual backup
  startCapsuleBackup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.setCapsuleManualBackup),
      switchMap((action) => {
        return this.httpClient
          .post<any>(
            `${environment.apiBaseUrl}/capsules/${action.capsuleId}/backups`,
            {
              spaceId: action.spaceId,
              productKey: 'BACKUP_TIER_3',
            }
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.setCapsuleManualBackupSuccess({
                backup: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.setCapsuleManualBackupFailed({ error }))
            )
          );
      })
    )
  );

  //restore backup
  restoreCapsuleBackup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.startCapsuleBackupRestore),
      switchMap((action) => {
        const capsuleId = action.capsuleId;
        const spaceId = action.spaceId;
        const backupId = action.backupId;

        return this.httpClient
          .post<any>(
            `${environment.apiBaseUrl}/capsules/${capsuleId}/backups/${backupId}/restores?spaceId=${spaceId}`,
            {}
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.startCapsuleBackupRestoreSuccess({
                restore: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.startCapsuleBackupRestoreFailed({ error }))
            )
          );
      })
    )
  );

  // Delete capsule backup
  deleteCapsuleBackup = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.deleteCapsuleBackup),
      switchMap((action) => {
        const spaceId = action.spaceId;
        const capsuleId = action.capsuleId;
        const backupId = action.backupId;

        return this.httpClient
          .get<APIResponse<Backup>>(
            `${environment.apiBaseUrl}/capsules/${capsuleId}/backups?spaceId=${spaceId},backupId=${backupId}`
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.deleteCapsuleBackupSuccess({
                backupId: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.deleteCapsuleBackupFailed(error))
            )
          );
      })
    )
  );

  fetchCapsuleRestores = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.fetchCapsuleRestores),
      switchMap((action) => {
        const capsuleId = action.capsuleId;
        const spaceId = action.spaceId;
        return this.httpClient
          .get<APIResponse<Backup>>(
            `${environment.apiBaseUrl}/capsules/${capsuleId}/restores?spaceId=${spaceId}`
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.fetchCapsuleRestoresSuccess({
                restores: apiResponse.data,
                capsuleId: action.capsuleId,
              });
            }),
            catchError((error) => {
              return of(CapsuleActions.fetchCapsuleRestoresFailed(error));
            })
          );
      })
    )
  );

  //fetch backups
  fetchCapsuleBackups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.fetchCapsuleBackups),
      switchMap((action) => {
        const capsuleId = action.capsule.id;
        const spaceId = action.space.id;
        return this.httpClient
          .get<APIResponse<Backup[]>>(
            `${environment.apiBaseUrl}/capsules/${capsuleId}/backups?spaceId=${spaceId}`
          )
          .pipe(
            map((apiResponse) =>
              CapsuleActions.fetchCapsuleBackupsSuccess({
                backups: apiResponse.data || [],
                capsule: action.capsule,
              })
            ),
            catchError((error) =>
              of(CapsuleActions.fetchCapsuleBackupsFailed({ error }))
            )
          );
      })
    )
  );

  // set capsule auto backup
  setCapsuleBackupSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.setCapsuleBackupSchedule),
      concatMap((action) => {
        const capsuleId = action.capsule.id;
        const spaceId = action.space.id;
        const backupSchedule = action.backupSchedule;

        return this.httpClient
          .put<any>(
            `${environment.apiBaseUrl}/capsules/${capsuleId}/backup-schedule?spaceId=${spaceId}`,
            backupSchedule
          )
          .pipe(
            map((apiResponse) =>
              CapsuleActions.setCapsuleBackupScheduleSuccess({
                backupSchedule: apiResponse.data,
              })
            ),
            catchError((error) =>
              of(CapsuleActions.setCapsuleBackupScheduleFailed(error))
            )
          );
      })
    )
  );

  // fetch capsule auto backup
  fetchCapsuleBackupSchedule = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.fetchCapsuleBackupSchedule),
      concatMap((action) => {
        const capsuleId = action.capsuleId;
        const spaceId = action.space.id;

        return this.httpClient
          .get<any>(
            `${environment.apiBaseUrl}/capsules/${capsuleId}/backup-schedule?spaceId=${spaceId}`
          )
          .pipe(
            map((apiResponse) => {
              return CapsuleActions.fetchCapsuleBackupScheduleSuccess({
                backupSchedule: apiResponse.data,
              });
            }),
            catchError((error) =>
              of(CapsuleActions.fetchCapsuleBackupScheduleFailed(error))
            )
          );
      })
    )
  );

  deleteCapsuleBackupSchedule = createEffect(() =>
    this.actions$.pipe(
      ofType(CapsuleActions.deleteCapsuleBackupSchedule),
      concatMap((action) => {
        const capsuleId = action.capsule.id;
        const spaceId = action.space.id;

        return this.httpClient
          .delete<any>(
            `${environment.apiBaseUrl}/capsules/${capsuleId}/backup-schedule?spaceId=${spaceId}`
          )
          .pipe(
            map((apiResponse) =>
              CapsuleActions.deleteCapsuleBackupScheduleSuccess({
                capsuleId: action.capsule.id,
              })
            ),
            catchError((error) =>
              of(CapsuleActions.deleteCapsuleBackupScheduleFailed(error))
            )
          );
      })
    )
  );
}
